<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <div class="mb-1 text-right">
          <b-button
            to="banka-hesap-bilgileri/ekle"
            variant="primary"
          ><FeatherIcon icon="PlusIcon" />
            Ekle</b-button>
        </div>
        <b-card-text>
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
          >
            <template #cell(bank)="data">
              <div>{{ data.item.bank }}</div>
              <div class="text-primary font-small-2">
                {{ data.item.title }}
              </div>
              <div class="text-muted font-small-2">
                {{ data.item.iban }}
              </div>
            </template>
            <template #cell(control)="data">
              <b-button
                :to="'/admin/banka-hesap-bilgileri/guncelle/' + data.item.id"
                variant="outline-primary"
                size="sm"
                class="mr-1"
              >
                Güncelle
              </b-button>
              <b-button
                variant="outline-danger"
                size="sm"
                @click="removeBank(data.item.id)"
              >
                Sil
              </b-button>
            </template>
          </b-table>
        </b-card-text>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BTable, BCardFooter, BPagination, BButton,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardText,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      search: '',
      fields: [
        {
          key: 'bank',
          label: 'Banka',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
        },
        {
          key: 'control',
          label: 'Kontrol',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          'bank_accounts.id AS id',
          'bank_accounts.title AS title',
          'bank_accounts.bank AS bank',
          'bank_accounts.iban AS iban',
        ],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['bankAccounts/getBankAccounts']
    },
    dataCount() {
      return this.$store.getters['bankAccounts/getBankAccountsCount']
    },
    saveStatus() {
      return this.$store.getters['bankAccounts/getBankAccountSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
    saveStatus(val) {
      if (val.status) {
        this.getDataList()
      }
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    removeBank(id) {
      this.$store.dispatch('bankAccounts/bankAccountRemove', id)
    },
    getDataList() {
      this.$store.dispatch('bankAccounts/bankAccountsList', this.dataQuery)
    },
  },
}
</script>
